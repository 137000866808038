// colors
$color-background: #091916;
$color-gradient: linear-gradient(237.12deg, #6BFFDC 0%, #65FF91 50.52%, #84ED62 100%);
$color-gradient-box-shadow: (0 0 5px -2px #6BFFDC, 0 0 7px 0 #65FF91, 0 0 24px 3px #84ED62);
$color-gradient-text: #69FE8D;
$color-success: #6DF37D;
$color-error: #F94A3F;
$color-pending: #C3A6FC;
$color-primary: #fff;
$color-secondary: #4C5C58;
$color-tertiary: #12322A;
$color-fourth: #2A463F;
$color-currency: #286052;
$color-background-gradient: linear-gradient(180deg, #0A1A17 0%, #13322A 100%);
$color-crypto-payments: invert(53%) sepia(21%) saturate(719%) hue-rotate(208deg) brightness(87%) contrast(85%);
$color-input-active: url(/assets/img/theme/default/input-gradient.svg);
$theme-features-icon: url(/assets/img/theme/default/features/theme-features-icon.png);
$active-menu-gradient: -webkit-linear-gradient(237deg, #6BFFDC 0%, #65FF91 50.52%, #84ED62 100%);
$accordion-gradient: linear-gradient(to bottom, rgba(14, 39, 33, 0) 0%, #091916);
$payments-gradient: linear-gradient(90deg, rgba(7, 4, 28, 0) 0%, #091916 100%);

// vars
$color-gradient-box-shadow-var: --color-gradient-box-shadow;
$color-background-var: --color-background;
$color-gradient-var: --color-gradient;
$color-gradient-text-var: --color-gradient-text;
$color-success-var: --color-success;
$color-error-var: --color-error;
$color-pending-var: --color-pending;
$color-primary-var: --color-primary;
$color-secondary-var: --color-secondary;
$color-tertiary-var: --color-tertiary;
$color-fourth-var: --color-fourth;
$color-currency-var: --color-currency;
$color-background-gradient-var: --color-background-gradient;
$color-input-active-var: --color-input-active;
$accordion-gradient-var: --accordion-gradient;
$payments-gradient-var: --payments-gradient;

// generated by https://responsive-css.spritegen.com/
$theme-features-icon-var: --theme-features-icon;
$theme-quotes-icon-var: --theme-quotes-icon;

$theme-map-default-dark: (
	$payments-gradient-var: $payments-gradient,
	$color-gradient-box-shadow-var: (0 0 5px -2px #6BFFDC, 0 0 7px 0 #65FF91, 0 0 24px 3px #84ED62),
	$color-background-var: $color-background,
  $color-gradient-var: $color-gradient,
  $color-gradient-text-var: $color-gradient-text,
  $color-success-var: $color-success,
  $color-error-var: $color-error,
  $color-pending-var: $color-pending,
  $color-primary-var: #fff,
  $color-secondary-var: $color-secondary,
  $color-tertiary-var: $color-tertiary,
  $color-fourth-var: $color-fourth,
  $color-currency-var: $color-currency,
  $accordion-gradient-var: $accordion-gradient,
  $color-background-gradient-var: $color-background-gradient,
	$color-input-active-var: url(/assets/img/theme/default/input-gradient.svg),
  $theme-features-icon-var: url(/assets/img/theme/default/features/theme-features-icon.png),
	$theme-quotes-icon-var: url(/assets/img/blog/icons/theme/default-quotes.svg),
);

// fonts
$font-default: "Alexandria";

// font size
$font-size-default: 16px;

// transitions
$transition-time-default: 0.2s;
$theme-transition-duration: 1500ms;

// breakpoints
$bp-xs-ios: 340px;
$bp-iphone-6-8: 376px;
$bp-xs: 536px;
$bp-sm: 768px;
$bp-md: 992px;
$bp-ld: 1200px;
$bp-xl: 1500px;
