@mixin icon-styles {
  font-family: "icons";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  // speak: none; // only necessary if not using the private unicode range (firstGlyph option)
  text-decoration: none;
  text-transform: none;
}

%icon {
  @include icon-styles;
}

@function icon-char($filename) {
  $char: "";

  @if $filename == app {
  $char: "\E001";
}
  @if $filename == apply-fat {
  $char: "\E002";
}
  @if $filename == apply {
  $char: "\E003";
}
  @if $filename == arrow {
  $char: "\E004";
}
  @if $filename == attention {
  $char: "\E005";
}
  @if $filename == burger {
  $char: "\E006";
}
  @if $filename == close {
  $char: "\E007";
}
  @if $filename == copy-bold {
  $char: "\E008";
}
  @if $filename == copy {
  $char: "\E009";
}
  @if $filename == deposit {
  $char: "\E00A";
}
  @if $filename == doc {
  $char: "\E00B";
}
  @if $filename == edit {
  $char: "\E00C";
}
  @if $filename == email {
  $char: "\E00D";
}
  @if $filename == error {
  $char: "\E00E";
}
  @if $filename == exchange {
  $char: "\E00F";
}
  @if $filename == favourite-filled {
  $char: "\E010";
}
  @if $filename == favourite {
  $char: "\E011";
}
  @if $filename == few-games {
  $char: "\E012";
}
  @if $filename == filter {
  $char: "\E013";
}
  @if $filename == full {
  $char: "\E014";
}
  @if $filename == gift {
  $char: "\E015";
}
  @if $filename == info {
  $char: "\E016";
}
  @if $filename == informer {
  $char: "\E017";
}
  @if $filename == jpg {
  $char: "\E018";
}
  @if $filename == like {
  $char: "\E019";
}
  @if $filename == like_filled {
  $char: "\E01A";
}
  @if $filename == long-arrow-left {
  $char: "\E01B";
}
  @if $filename == long-arrow-right {
  $char: "\E01C";
}
  @if $filename == more {
  $char: "\E01D";
}
  @if $filename == notify {
  $char: "\E01E";
}
  @if $filename == one-game {
  $char: "\E01F";
}
  @if $filename == password {
  $char: "\E020";
}
  @if $filename == pdf {
  $char: "\E021";
}
  @if $filename == picture-in-picture {
  $char: "\E022";
}
  @if $filename == play {
  $char: "\E023";
}
  @if $filename == plus-bold {
  $char: "\E024";
}
  @if $filename == plus {
  $char: "\E025";
}
  @if $filename == question {
  $char: "\E026";
}
  @if $filename == reroll {
  $char: "\E027";
}
  @if $filename == search {
  $char: "\E028";
}
  @if $filename == signin {
  $char: "\E029";
}
  @if $filename == signup {
  $char: "\E02A";
}
  @if $filename == support {
  $char: "\E02B";
}
  @if $filename == swipe-pointer {
  $char: "\E02C";
}
  @if $filename == tournaments {
  $char: "\E02D";
}
  @if $filename == trash {
  $char: "\E02E";
}
  @if $filename == user {
  $char: "\E02F";
}
  @if $filename == wallet {
  $char: "\E030";
}

@return $char;
}

@mixin icon($filename, $insert: before, $extend: true) {
&:#{$insert} {
  @if $extend {
    @extend %icon;
  } @else {
    @include icon-styles;
  }
  content: icon-char($filename);
}
}

.icon-app {
  @include icon(app);
}
.icon-apply-fat {
  @include icon(apply-fat);
}
.icon-apply {
  @include icon(apply);
}
.icon-arrow {
  @include icon(arrow);
}
.icon-attention {
  @include icon(attention);
}
.icon-burger {
  @include icon(burger);
}
.icon-close {
  @include icon(close);
}
.icon-copy-bold {
  @include icon(copy-bold);
}
.icon-copy {
  @include icon(copy);
}
.icon-deposit {
  @include icon(deposit);
}
.icon-doc {
  @include icon(doc);
}
.icon-edit {
  @include icon(edit);
}
.icon-email {
  @include icon(email);
}
.icon-error {
  @include icon(error);
}
.icon-exchange {
  @include icon(exchange);
}
.icon-favourite-filled {
  @include icon(favourite-filled);
}
.icon-favourite {
  @include icon(favourite);
}
.icon-few-games {
  @include icon(few-games);
}
.icon-filter {
  @include icon(filter);
}
.icon-full {
  @include icon(full);
}
.icon-gift {
  @include icon(gift);
}
.icon-info {
  @include icon(info);
}
.icon-informer {
  @include icon(informer);
}
.icon-jpg {
  @include icon(jpg);
}
.icon-like {
  @include icon(like);
}
.icon-like_filled {
  @include icon(like_filled);
}
.icon-long-arrow-left {
  @include icon(long-arrow-left);
}
.icon-long-arrow-right {
  @include icon(long-arrow-right);
}
.icon-more {
  @include icon(more);
}
.icon-notify {
  @include icon(notify);
}
.icon-one-game {
  @include icon(one-game);
}
.icon-password {
  @include icon(password);
}
.icon-pdf {
  @include icon(pdf);
}
.icon-picture-in-picture {
  @include icon(picture-in-picture);
}
.icon-play {
  @include icon(play);
}
.icon-plus-bold {
  @include icon(plus-bold);
}
.icon-plus {
  @include icon(plus);
}
.icon-question {
  @include icon(question);
}
.icon-reroll {
  @include icon(reroll);
}
.icon-search {
  @include icon(search);
}
.icon-signin {
  @include icon(signin);
}
.icon-signup {
  @include icon(signup);
}
.icon-support {
  @include icon(support);
}
.icon-swipe-pointer {
  @include icon(swipe-pointer);
}
.icon-tournaments {
  @include icon(tournaments);
}
.icon-trash {
  @include icon(trash);
}
.icon-user {
  @include icon(user);
}
.icon-wallet {
  @include icon(wallet);
}
